import React, { useEffect, useState } from "react";
import "./Style.css"; // Assuming a CSS file for styling
import { db } from "../util/firebase"; // Assuming `db` is a valid Firestore instance
import { collection, getDocs, query } from "firebase/firestore"; // Import necessary functions
import toast, { Toaster } from "react-hot-toast";
const clientId =
  "869508749472-fr6qc1lmht8fpic7bh3cophoe3h83hj0.apps.googleusercontent.com";
export const ViewUser = () => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [error, setError] = useState(null); // Store any errors

  const fetchUsers = async () => {
    setIsLoading(true); // Set loading state to true
    setError(null); // Clear any previous errors

    try {
      const usersRef = collection(db, "users"); // Create a reference to the users collection
      const q = query(usersRef); // Create a query to retrieve all users

      const querySnapshot = await getDocs(q); // Fetch user data
      const fetchedUsers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(), // Extract user data and add ID
      }));

      setUserList(fetchedUsers); // Update state with fetched user data
    } catch (err) {
      console.error("Error fetching users:", err); // Log error for debugging
      setError("An error occurred while fetching users."); // Set error message
    } finally {
      setIsLoading(false); // Set loading state to false after fetching or error
    }
  };

  useEffect(() => {
    fetchUsers(); // Fetch users on component mount
  }, []);

  return (
    <div>
      {isLoading ? (
        <p>Loading users...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <ul>
          {userList.map((user) => (
            <li key={user.id}>  {/* Use ID as a unique key */}
              {user.name} {/* Assuming a 'name' field in the user data */}
              {user.email} {/* Assuming an 'email' field in the user data */}
            </li>
          ))}
        </ul>
      )}
      <Toaster /> {/* Add Toaster component for notifications (optional) */}
    </div>
  );
};
